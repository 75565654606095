<template>
  <div class="box">
    <div class="box-header with-border">
      <h3>
        <previous-route-block/>

        {{ page_title }}
      </h3>

      <div v-if="[1, 2, 7, 8, 9].includes(this.$store.getters['auth/authUser'].role.id) && !onProgress"
           class="form-group mt-3">
        <input type="radio" class="btn-check" name="options-outlined" id="show_only-my"
               @input="() => {
                 filter_tasks.show_only = 'my';
                 updateFilters();
               }" autocomplete="off" :checked="filter_tasks.show_only === 'my'">
        <label class="btn btn-outline-primary" for="show_only-my">Мои</label>

        <input type="radio" class="btn-check" name="options-outlined" id="show_only-all"
               @input="() => {
                 filter_tasks.show_only = 'all';
                 updateFilters();
               }" autocomplete="off" :checked="filter_tasks.show_only === 'all'">
        <label class="btn btn-outline-primary" for="show_only-all">Все</label>
      </div>

      <template v-if="task_types && onProgress === false">
        <span v-for="task_type in task_types"
              class="label label-default text-white font-medium pointer"
              v-on:click="() => {
                if (filter_tasks.type_id !== task_type.id) {
                  filter_tasks.type_id = task_type.id;
                } else {
                  filter_tasks.type_id = null;
                }
                updateFilters();
              }"
              :style="'padding: 2px 6px; font-size: 14px; background:' + task_type.custom_color.replace('0.25', '1') + '; ' + (filter_tasks.type_id === task_type.id ? ('border: 3px solid #fff; font-size: 18px;') : '')">
          {{ task_type.name }}
        </span>
      </template>
    </div>

    <div class="box-body">
      <transition name="fade">
        <div class="form-group">
          <div class="row">
            <label class="col-xl-3 col-md-6 col-sm-12 mb-1" for="filter-by-branch">
              <multiselect :allow-empty="true"
                           :options="branches"
                           :searchable="false"
                           :show-labels="true"
                           id="filter-by-branch"
                           @input="() => {
                             filter_tasks.branch_id = branch ? branch.id : null;
                             getEmployees(filter_tasks.branch_id);
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="name"
                           placeholder="Филиал"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Филиал"
                           track-by="id"
                           v-model="branch">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
                <template slot="singleLabel" slot-scope="props">
                  {{ props.option.title }}, {{ props.option.address }}
                </template>
                <template slot="option" slot-scope="props">
                  {{ props.option.title }}, <span class="text-muted">{{ props.option.address }}</span>
                </template>
              </multiselect>
            </label>

            <label class="col-xl-3 col-md-6 col-sm-12 mb-1" for="filter-by-branch">
              <multiselect :allow-empty="true"
                           :options="cities"
                           :searchable="false"
                           :show-labels="true"
                           id="filter-by-city"
                           @input="() => {
                             filter_tasks.city_id = city ? city.id : null;
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="title"
                           placeholder="Город"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Город"
                           track-by="id"
                           v-model="city">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
                <template slot="singleLabel" slot-scope="props">
                  {{ props.option.title }}
                </template>
                <template slot="option" slot-scope="props">
                  {{ props.option.title }}
                </template>
              </multiselect>
            </label>

            <label v-if="filter_tasks.show_only !== 'my'"
                   class="col-xl-3 col-md-6 col-sm-12 mb-1" for="filter-by-responsible">
              <multiselect :allow-empty="true"
                           :options="employees"
                           :searchable="true"
                           id="filter-by-responsible"
                           @input="() => {
                             filter_tasks.responsible_id = responsible ? responsible.id : null;
                             updateFilters();
                           }"
                           deselect-label="Убрать"
                           label="title"
                           placeholder="Ответственный"
                           required
                           select-label="Выбрать"
                           selected-label="Выбрано"
                           title="Ответственный"
                           track-by="id"
                           v-model="responsible">
                <template slot="noResult">Не найдено</template>
                <template slot="noOptions">Данные отсутствуют</template>
              </multiselect>
            </label>
          </div>
        </div>
      </transition>

      <card-items :data_search="data_search"
                  :filters_query="filter_tasks"
                  :relations="relations"
                  :relations_count="relations_count"
                  component="task-income-item"
                  item_classes="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-4"
                  api_resource="/tasks">
      </card-items>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import CardItems from './../../../../components/pagination/card-items';
import BranchService from "@/services/BranchService";
import Multiselect from 'vue-multiselect';

Vue.component('task-income-item', (resolve) => {
  return require(['./item'], resolve);
});

export default {
  name: 'tasks',

  components: {
    Multiselect,
    CardItems,
  },

  props: {
    data_search: {
      type: String,
      required: false
    },
  },

  metaInfo() {
    return {
      title: this.page_title,
    }
  },

  data: () => ({
    page_title: 'Входящие звонки',

    task_types: [],
    tasks: [],

    branches: [],
    branch: null,
    cities: [],
    city: null,
    employees: [],
    responsible: null,

    relations: [
      'branch',
      'car.branch',
      'client',
      'type',
      'type_status',
      'responsible.day_off_responsible',
      'creator',
      'last_comment',
    ],
    relations_count: [],

    filter_tasks: {
      only_incomes: true,
      show_only: null,
      branch_id: null,
      city_id: null,
      responsible_id: null,
      type_id: null,
    },

    onProgress: true,
  }),

  async created() {
    try {
      this.EventBus.$on('task-items-reload', this.updateFilters);

      if ([1, 2, 7, 8, 9].includes(this.$store.getters['auth/authUser'].role.id)) {
        this.filter_tasks.show_only = 'all';
      } else {
        this.filter_tasks.show_only = 'my';
      }

      this.branches = await BranchService.getItems();
      this.cities = [...new Map(this.branches.map(branch => [branch.city_id, {
        "id": branch.city_id,
        "title": branch.title,
      }])).values()];

      await this.getTaskTypes();
      await this.getEmployees();

      // if (localStorage.getItem('filter_tasks_incoming')) {
      //   this.filter_tasks = JSON.parse(localStorage.getItem('filter_tasks_incoming'));
      //   if (this.filter_tasks.branch_id) {
      //     this.branch = this.branches.find((branch) => {
      //       return branch.id === this.filter_tasks.branch_id;
      //     });
      //   }
      //   if (this.filter_tasks.responsible_id) {
      //     this.responsible = this.employees.find((employee) => {
      //       return employee.id === this.filter_tasks.responsible_id;
      //     });
      //   }
      // }
    } catch (error) {
      errorHandler(error);
    }

    this.onProgress = false;
  },

  methods: {
    updateFilters() {
      // localStorage.setItem('filter_tasks_incoming', JSON.stringify(this.filter_tasks));
      this.EventBus.$emit('card-filter-changed');
    },
    async getTaskTypes() {
      try {
        const task_types_response_items = await API.apiClient.get('/tasks/types', {
          params: {
            filters_query: {
              only_incoming_types: true,
            },
          }
        });

        this.task_types = task_types_response_items.data;
      } catch (error) {
        errorHandler(error);
      }
    },
    async getEmployees(branchID = null) {
      try {
        if ([1, 2, 7, 8, 9].includes(this.$store.getters['auth/authUser'].role.id)) {

          const employees_response = await API.apiClient.get('/employees', {
            params: {
              filters_query: {
                is_paginated: 0,
                branch_id: branchID
              },
            }
          });

          this.employees = employees_response.data;
        }
      } catch (error) {
        errorHandler(error);
      }
    },
  },
}
</script>

<style scoped>
</style>
