var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('div',{staticClass:"box-header with-border"},[_c('h3',[_c('previous-route-block'),_vm._v(" "+_vm._s(_vm.page_title)+" ")],1),([1, 2, 7, 8, 9].includes(this.$store.getters['auth/authUser'].role.id) && !_vm.onProgress)?_c('div',{staticClass:"form-group mt-3"},[_c('input',{staticClass:"btn-check",attrs:{"type":"radio","name":"options-outlined","id":"show_only-my","autocomplete":"off"},domProps:{"checked":_vm.filter_tasks.show_only === 'my'},on:{"input":() => {
               _vm.filter_tasks.show_only = 'my';
               _vm.updateFilters();
             }}}),_c('label',{staticClass:"btn btn-outline-primary",attrs:{"for":"show_only-my"}},[_vm._v("Мои")]),_c('input',{staticClass:"btn-check",attrs:{"type":"radio","name":"options-outlined","id":"show_only-all","autocomplete":"off"},domProps:{"checked":_vm.filter_tasks.show_only === 'all'},on:{"input":() => {
               _vm.filter_tasks.show_only = 'all';
               _vm.updateFilters();
             }}}),_c('label',{staticClass:"btn btn-outline-primary",attrs:{"for":"show_only-all"}},[_vm._v("Все")])]):_vm._e(),(_vm.task_types && _vm.onProgress === false)?_vm._l((_vm.task_types),function(task_type){return _c('span',{staticClass:"label label-default text-white font-medium pointer",style:('padding: 2px 6px; font-size: 14px; background:' + task_type.custom_color.replace('0.25', '1') + '; ' + (_vm.filter_tasks.type_id === task_type.id ? ('border: 3px solid #fff; font-size: 18px;') : '')),on:{"click":() => {
              if (_vm.filter_tasks.type_id !== task_type.id) {
                _vm.filter_tasks.type_id = task_type.id;
              } else {
                _vm.filter_tasks.type_id = null;
              }
              _vm.updateFilters();
            }}},[_vm._v(" "+_vm._s(task_type.name)+" ")])}):_vm._e()],2),_c('div',{staticClass:"box-body"},[_c('transition',{attrs:{"name":"fade"}},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-xl-3 col-md-6 col-sm-12 mb-1",attrs:{"for":"filter-by-branch"}},[_c('multiselect',{attrs:{"allow-empty":true,"options":_vm.branches,"searchable":false,"show-labels":true,"id":"filter-by-branch","deselect-label":"Убрать","label":"name","placeholder":"Филиал","required":"","select-label":"Выбрать","selected-label":"Выбрано","title":"Филиал","track-by":"id"},on:{"input":() => {
                           _vm.filter_tasks.branch_id = _vm.branch ? _vm.branch.id : null;
                           _vm.getEmployees(_vm.filter_tasks.branch_id);
                           _vm.updateFilters();
                         }},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_vm._v(" "+_vm._s(props.option.title)+", "+_vm._s(props.option.address)+" ")]}},{key:"option",fn:function(props){return [_vm._v(" "+_vm._s(props.option.title)+", "),_c('span',{staticClass:"text-muted"},[_vm._v(_vm._s(props.option.address))])]}}]),model:{value:(_vm.branch),callback:function ($$v) {_vm.branch=$$v},expression:"branch"}},[_c('template',{slot:"noResult"},[_vm._v("Не найдено")]),_c('template',{slot:"noOptions"},[_vm._v("Данные отсутствуют")])],2)],1),_c('label',{staticClass:"col-xl-3 col-md-6 col-sm-12 mb-1",attrs:{"for":"filter-by-branch"}},[_c('multiselect',{attrs:{"allow-empty":true,"options":_vm.cities,"searchable":false,"show-labels":true,"id":"filter-by-city","deselect-label":"Убрать","label":"title","placeholder":"Город","required":"","select-label":"Выбрать","selected-label":"Выбрано","title":"Город","track-by":"id"},on:{"input":() => {
                           _vm.filter_tasks.city_id = _vm.city ? _vm.city.id : null;
                           _vm.updateFilters();
                         }},scopedSlots:_vm._u([{key:"singleLabel",fn:function(props){return [_vm._v(" "+_vm._s(props.option.title)+" ")]}},{key:"option",fn:function(props){return [_vm._v(" "+_vm._s(props.option.title)+" ")]}}]),model:{value:(_vm.city),callback:function ($$v) {_vm.city=$$v},expression:"city"}},[_c('template',{slot:"noResult"},[_vm._v("Не найдено")]),_c('template',{slot:"noOptions"},[_vm._v("Данные отсутствуют")])],2)],1),(_vm.filter_tasks.show_only !== 'my')?_c('label',{staticClass:"col-xl-3 col-md-6 col-sm-12 mb-1",attrs:{"for":"filter-by-responsible"}},[_c('multiselect',{attrs:{"allow-empty":true,"options":_vm.employees,"searchable":true,"id":"filter-by-responsible","deselect-label":"Убрать","label":"title","placeholder":"Ответственный","required":"","select-label":"Выбрать","selected-label":"Выбрано","title":"Ответственный","track-by":"id"},on:{"input":() => {
                           _vm.filter_tasks.responsible_id = _vm.responsible ? _vm.responsible.id : null;
                           _vm.updateFilters();
                         }},model:{value:(_vm.responsible),callback:function ($$v) {_vm.responsible=$$v},expression:"responsible"}},[_c('template',{slot:"noResult"},[_vm._v("Не найдено")]),_c('template',{slot:"noOptions"},[_vm._v("Данные отсутствуют")])],2)],1):_vm._e()])])]),_c('card-items',{attrs:{"data_search":_vm.data_search,"filters_query":_vm.filter_tasks,"relations":_vm.relations,"relations_count":_vm.relations_count,"component":"task-income-item","item_classes":"col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12 mb-4","api_resource":"/tasks"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }